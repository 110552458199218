(function () {
	'use strict';

	angular
		.module('app')
		.factory('manageCalendarActions', [
			'$location',
			'seedcodeCalendar',
			'utilities',
			'environment',
			'$timeout',
			'$compile',
			'$rootScope',
			'fullCalendarBridge',
			manageCalendarActions,
		]);

	function manageCalendarActions(
		$location,
		seedcodeCalendar,
		utilities,
		environment,
		$timeout,
		$compile,
		$rootScope,
		fullCalendarBridge
	) {
		return {
			assignValidCalendarActions: assignValidCalendarActions,
			runCalendarActions: runCalendarActions,
			callEventAction: callEventAction,
			substituteOpenCommand: substituteOpenCommand,
		};

		function isEnabled(action) {
			//We don't want to run calendar actions if we are not on the main route (calendar route)
			if (
				$location.path() !== '/' &&
				$location.path().indexOf('/shared/') < 0 &&
				action?.type !== 'draftModeToggle'
			) {
				return false;
			} else {
				return true;
			}
		}

		function assignValidCalendarActions(actions) {
			var config = seedcodeCalendar.get('config');
			var type;
			var result = {};

			if (!actions) {
				return;
			}

			for (var property in actions) {
				if (
					!actions[property].actionEnabledFor ||
					!actions[property].type
				) {
					continue;
				}

				type = actions[property].type;

				if (!result[type]) {
					result[type] = {};
				}

				if (
					config.isShare &&
					actions[property].actionEnabledFor.shares
				) {
					result[type][actions[property].id] = actions[property];
				} else if (
					!config.isShare &&
					actions[property].actionEnabledFor.app
				) {
					result[type][actions[property].id] = actions[property];
				}
			}

			return Object.keys(result).length ? result : null;
		}

		// App actions
		function runCalendarActions(actions, actionCallbacks, actionData) {
			let hasPreventDefault;
			let preventDefaultActionOverride;
			const actionPromises = [];

			//Assign helper functions
			//this is the dbk object in the custom action scope
			//helper functions are now defined at the top of the calendarInit.init.
			var helpers = seedcodeCalendar.get('actionHelpers');

			//We don't want to run calendar actions if we are not on the main route (calendar route)
			// This will be checking only actions of a specific type. So we can just grab the first one
			// to make sure an action of that type exists
			if (!actions || !isEnabled(Object.values(actions)[0])) {
				return;
			}

			if (!actionCallbacks) {
				actionCallbacks = {
					confirm: function () {
						//No action
					},
					cancel: function () {
						//No action
					},
				};
			}

			for (var property in actions) {
				if (actions[property].preventDefault) {
					hasPreventDefault = true;
					actionPromises.push(
						new Promise((resolve, reject) => {
							const promiseCallbacks = {
								confirm: () => {
									resolve();
								},
								cancel: () => {
									reject();
								},
							};
							const params = {
								data: actionData,
								callbacks: promiseCallbacks,
							};

							actions[property].callbacks = promiseCallbacks;
							executeFunction(
								actions[property].actionCode,
								actions[property],
								helpers,
								params
							);
						})
					);
				} else {
					const params = {
						data: actionData,
						callbacks: {
							confirm: () => {
								// do nothing
							},
							cancel: () => {
								// do nothing
							},
						},
					};

					actions[property].callbacks = params.callbacks;
					const actionResult = executeFunction(
						actions[property].actionCode,
						actions[property],
						helpers,
						params
					);

					if (actionResult === true) {
						preventDefaultActionOverride = true;
					}
				}
			}
			if (hasPreventDefault) {
				Promise.all(actionPromises)
					.then((result) => {
						actionCallbacks.confirm();
					})
					.catch((err) => {
						actionCallbacks.cancel();
					});
			}

			return hasPreventDefault || preventDefaultActionOverride;
		}

		function callEventAction(
			action,
			url,
			editEvent,
			jsEvent,
			targetElement,
			params,
			changesObject,
			revertObject,
			isUndo,
			callback
		) {
			//We don't want to run calendar actions if we are not on the main route (calendar route)
			if (!isEnabled(action)) {
				return;
			}

			//If we don't have an event don't execute action. This would happen if there was an error saving the event for example.
			if (!editEvent) {
				return;
			}

			// If we don't have a url provided (the action function) but do have a callback, run that and exit
			if (!url) {
				if (callback) {
					callback();
				}
				return;
			}

			//Assign the executed result ot our result var so the parent function can return it
			const helpers = seedcodeCalendar.get('actionHelpers');

			// Assign tooltip helper here so we have access to targetElement
			helpers.tooltip = function (content, options) {
				//Options is an object containing additional properties
				if (!options) {
					options = {};
				}

				const toolTipElement = options.targetElement
					? options.targetElement
					: targetElement;
				const placement = options.placement
					? options.placement
					: 'auto';
				const container = options.container
					? options.container
					: 'body';
				const delay = options.delay >= 0 ? options.delay : 350;
				const hideOnClick =
					options.hideOnClick === false ? false : true;

				let className = '';

				if (options.className) {
					className += options.className;
				}

				if (options?.event?.unscheduled) {
					if (className) {
						className += ' ';
					}
					className += 'tooltip-unscheduled';
				}

				// Don't bother initiating if on mobile and hide on click as mobile will hide before it's shown
				if (environment.isMobileDevice && hideOnClick) {
					return;
				}
				const tooltipResult = utilities.tooltip(
					toolTipElement,
					content,
					className,
					placement,
					container,
					null,
					delay,
					hideOnClick,
					options
				);
				return tooltipResult;
			};
			const cleanUrl = replaceFieldTokens(url, editEvent);
			const result = executeFunction(
				cleanUrl,
				action,
				helpers,
				params,
				jsEvent,
				targetElement,
				editEvent,
				changesObject,
				revertObject
			);

			return result;
		}

		// Execute action and clear global items in scope
		function executeFunction(
			func,
			action,
			dbk,
			params,
			jsEvent,
			targetElement,
			editEvent,
			changesObject,
			revertObject,
			isUndo
		) {
			//We don't want to run calendar actions if we are not on the main route (calendar route)
			if (!isEnabled(action)) {
				return;
			}

			// Need to declare config as var because it is referenced in actions for milan laser
			// Using var because actions may declare config with a const and we want it to override
			var config = seedcodeCalendar.get('config');

			// Temporarily adding these until we can replace instances in everyone's actions
			const eventChanged = dbk.eventChanged;
			const updateEditEvent = dbk.updateEditEvent;

			let executed;
			// We have old references to helpers in some actions people use so we need to make that accessible still
			const helpers = dbk;
			//ToDo: The way to make this truly secure is to execute this code in an iframe hosted on another domain. XSS security will prevent that iframe from accessing parent scope.
			//Sanitize window and global scope
			const window = undefined; //Assign a window var to prevent access to the window object. This is faster than trying to sanitize the whole window object.
			//Assign local vars that match global vars we want to block access to
			const angular = undefined;
			const Firebase = undefined;
			const firebase = undefined;
			const crypt = undefined;

			//Injected services and vars

			//extract showMessage from utilities before removing
			const calendarIO = undefined;

			let event;
			if (editEvent) {
				event = editEvent.event ? editEvent.event : editEvent;
			}

			//Execute function
			try {
				executed = eval(func);
			} catch (e) {
				if (e instanceof SyntaxError) {
					dbk.showMessage(
						'Syntax Error: ' + e.message,
						2000,
						8000,
						'error'
					);
				} else {
					dbk.showMessage('Error: ' + e.message, 2000, 8000, 'error');
				}
			}

			// Eval will return the content of the evaluation and if the content of func
			// ends in = true; then the eval return value is true. Check specifically for this
			// as we only want to honor a function call returning true.
			const boolEnd = func?.trim()?.slice(-6)?.includes('true;');
			return !boolEnd ? executed : false;
		}

		//
		// Utility functions
		//

		//function for opening windows in event actions - keep in context of execute function as action code potentially contains this function
		function newEventActionWindow(openUrl, target, features, replace) {
			window.open(openUrl, target, features, replace);
		}

		function substituteOpenCommand(url) {
			//regex for finding all open functions (we want to replace them if this is going to be in a callback)
			const openPatternMatch = /open\((.*?)\);?/g;
			let matches;
			let match;
			if (url && url.indexOf('open(') !== -1) {
				//Get an array of open function matches
				matches = url.match(openPatternMatch);

				for (var i = 0; i < matches.length; i++) {
					//Get an array of the match and the function argument
					match = openPatternMatch.exec(url);

					//Only replace if this is not a function of another object
					if (url.substring(match.index - 1, match.index) !== '.') {
						url = url.replace(
							match[0],
							'newEventActionWindow(' + match[1] + ');'
						);
					}
				}
			}
			return url;
		}

		function getValidShareDataMap(type, source, event) {
			var dataMap = source[type] ? source[type] : null;
			var sourceScheduleID = utilities.stringToID(event.shareScheduleID);
			var scheduleDataMap;

			if (source[sourceScheduleID]) {
				scheduleDataMap = source[sourceScheduleID][type];

				if (!dataMap && scheduleDataMap) {
					dataMap = {};
				}

				for (var property in scheduleDataMap) {
					dataMap[property] = scheduleDataMap[property];
				}
			}
			return dataMap;
		}

		function replaceFieldTokens(tokenizedValue, event) {
			const config = seedcodeCalendar.get('config');
			const shareSources = seedcodeCalendar.get('shareSources');
			let eventProperty;
			let fieldMap;
			let customFields;
			if (config.isShare) {
				for (var property in shareSources) {
					if (
						(event.shareSourceID === shareSources[property].id &&
							!shareSources[property].localParent) ||
						event.shareScheduleID === shareSources[property].id
					) {
						fieldMap = getValidShareDataMap(
							'fieldMap',
							shareSources[property],
							event
						);
						customFields = getValidShareDataMap(
							'customFields',
							shareSources[property],
							event
						);

						for (var field in customFields) {
							fieldMap[field] = customFields[field].field;
						}
						break;
					}
				}
			} else {
				fieldMap = event.schedule.fieldMap;
			}
			//Loop through our field map and replace tokens in the tokenized value with event data
			for (const property in fieldMap) {
				if (property === 'end' && event[property] && event.allDay) {
					eventProperty = event[property]
						.clone()
						.subtract(1, 'days')
						.toISOString();
				} else {
					eventProperty = moment.isMoment(event[property])
						? event[property].clone().toISOString()
						: String(event[property]);
				}
				//Escape double, single quotes and returns
				eventProperty = eventProperty
					? eventProperty
							.replace(/'/g, "\\'")
							.replace(/"/g, '\\"')
							.replace(/\n/g, '\\n')
					: eventProperty;
				tokenizedValue = tokenizedValue
					.split('[[' + fieldMap[property] + ']]')
					.join(eventProperty);
			}
			return tokenizedValue;
		}
	}
})();
